(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-customevent/assets/javascripts/webconsent-changed.js') >= 0) return;  svs.modules.push('/components/components/analytics-customevent/assets/javascripts/webconsent-changed.js');
"use strict";


class WebConsentChanged extends svs.components.analytics.customEvents {
  constructor(state) {
    if (typeof state === 'boolean') {
      super(['adobe'], 'consentChanged', {
        personalizedCustomer: state
      });
    } else {
      throw new Error('must specify state with boolean');
    }
  }
}
svs.components.analytics.customEvents.WebConsentChanged = WebConsentChanged;

 })(window);