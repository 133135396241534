(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-customevent/assets/javascripts/personalizedconsent-changed.js') >= 0) return;  svs.modules.push('/components/components/analytics-customevent/assets/javascripts/personalizedconsent-changed.js');
"use strict";


class PersonalizedConsentChanged extends svs.components.analytics.customEvents {
  constructor(state) {
    if (typeof state === 'boolean') {
      super(['adobe'], 'consentChanged', {
        personalizedBrowser: state
      });
    } else {
      throw new Error('must specify state with boolean');
    }
  }
}
svs.components.analytics.customEvents.PersonalizedConsentChanged = PersonalizedConsentChanged;

 })(window);