(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/analytics-customevent/assets/javascripts/custom-events.js') >= 0) return;  svs.modules.push('/components/components/analytics-customevent/assets/javascripts/custom-events.js');
"use strict";


svs.components = svs.components || {};
svs.components.analytics = svs.components.analytics || {};
svs.core = svs.core || {};
svs.core.analytics = svs.core.analytics || {};
svs.core.analytics.adl = svs.core.analytics.adl || {};
const logger = new svs.core.log.Logger('svs.analytics-event.CustomEvents');
const VALID_TARGETS = ['adobe', 'gtm'];
const validTargets = targets => {
  for (let i = 0; i < targets.length; i++) {
    if (VALID_TARGETS.indexOf(targets[i]) < 0) {
      throw new Error("invalid target ".concat(targets[i]));
    }
  }
};
class CustomEvent {
  constructor(targets, event, data) {
    if (this.constructor === CustomEvent) {
      throw new Error("Baseclass can't be instantiated.");
    }
    validTargets(targets);
    this.targets = targets;
    this.event = event;
    this.data = data;
  }
  get gtmOutput() {
    logger.debug('To use GTM you must implement data format');
    return undefined;
  }
  get adobeOutput() {
    return this.data;
  }
  track() {
    const msg = new svs.core.log.Message(svs.core.log.MessageType.customEvent);
    msg.eventname = this.event;
    msg.eventData = JSON.stringify(this.data);
    svs.core.log.track('svs.components.analytics', msg);
    if (this.targets.indexOf('adobe') > -1) {
      const data = this.adobeOutput;
      if (data) {
        Object.assign(svs.core.analytics.adl.consent, data);
        window.adobeDataLayer.adlPush('consentChanged', {
          site: svs.core.analytics.adl.site,
          consent: svs.core.analytics.adl.consent,
          user: svs.core.analytics.adl.user,
          page: svs.core.analytics.adl.page
        });
      }
    }
    if (this.targets.indexOf('gtm') > -1) {
      const data = this.gtmOutput;
      if (data) {
        dataLayerPush(data);
      }
    }
  }
}
svs.components.analytics.customEvents = CustomEvent;

 })(window);